<template>
  <div id="productViewByType">
    <h1>{{this.prd}}</h1>
    <div class="cardContainer">
      <div v-for="(product,index) in this.products" :key="index">
        <h4 v-if="product.series !== null">
          {{product.series}}
          <span class="series">series</span>
        </h4>
        <div class="sectionContainer">
          <div class v-for="(items,index) in product.items" :key="index">
            <div class="card" v-for="(item, index) in items" :key="index">
              <img :src="require('@/products/'+item.img)" />
              <div class="textContainer">
                <p>{{item.name}}</p>
                <small>{{item.id}}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      prd: "",
      products: []
    };
  },
  created() {
    this.prd = this.$route.params.product;
    this.listProducts();
  },
  watch: {
    "$route.params.product": function(product) {
      this.prd = product;
      this.listProducts();
    }
  },
  methods: {
    listProducts() {
      var link = this.prd + ".json";
      this.products = require("../products/data/" + link);
      var collectionMenu = document.getElementById("collectionMenuForHeaderPC");
      collectionMenu.classList.remove("collectionMenuShow");
    }
  }
};
</script>

<style lang="scss" scoped>
#productViewByType {
  width: 100%;
  padding: 1rem;
  margin-top: $headerHeight;
}
.series {
  margin-left: 5px;
  color: grey;
  font-weight: 300;
}
h1{
color:$darkBlue;
font-weight: 600;
}
.sectionContainer{
  display:inline-flex;
  flex-wrap:wrap;
  justify-content: center;
  @include media-md{
    justify-content: flex-start;
  }
}
.cardContainer {
  width: inherit;
  margin-top: 1rem;
  background-color: $white;
  overflow: hidden;
  .card {
    min-width: 200px;
    max-width: 250px;
    margin: 10px;
    margin-left:0;
    padding: 10px;
    box-shadow: 0px 1px 5px 1px #dedede;
    display: inline-block;
    transition:transform 0.5s;
    img {
      width: 100%;
      height: auto;
    }
    .textContainer {
      p {
        color: $blue;
        margin: 0;
      }
    }
    &:hover{
        transform:translateY(2px);
      }
  }
}
</style>
